<!--  教学场地使用情况统计表-->
<template>
    <div class="SiteUsageCensus">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">教学场地使用情况统计表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="150px">
            <el-form-item label="记录数">
                <el-input :value="form.record" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="安排次数">
                <el-input :value="form.arrange" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="综合占有率">
                <el-input :value="form.occupy" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="培训场地">
                <el-input :value="form.field" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="开始日期">
                <el-input :value="form.startDate" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="结束日期">
                <el-input :value="form.endDate" placeholder="" disabled></el-input>
            </el-form-item>
        </el-form>
        <div class="" style="width: 450px;text-align: right;color: #D51010;">
            注：每场地，教学能力，一天6节课，一周42节课
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   record:"",//记录数
                   arrange:"",//安排次数
                   occupy:"",//综合占有率
                   field:"",//培训场地
                   startDate:"",//开始日期
                   endDate:""//结束日期
                }
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            
        }
    }
</script>

<style scoped="scoped">
    .SiteUsageCensus{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    .el-input {
        width: 300px;
        height: 37px;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333;
    }
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
</style>
